body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

section {
  padding: 60px 20px;
}

h2 {
  margin-top: 0;
}


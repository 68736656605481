.about-section {
  padding: 60px 20px;
  background-color: #fff;
  color: #000;
  display: flex;
  height: 100vh; /* Full viewport height */
  flex-direction: column;
  align-items: center;
}


.about-content {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
}

.about-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-right {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: column;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.about-right h3 {
  margin-bottom: 30px;
  font-family: Monaco;
  font-size: 1rem;
}

.about-right p {
  font-size: 1rem;
  line-height: 1.6;
}

.about-right hr{
  width: 50%;
}

.carousel .slide img {
  width: 70%;
  border-radius: 10px;
}

.carousel .showarrows {
  color: aqua;
}

.carousel.carousel-slider .control-arrow {
  color: black !important;
}

.skills-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  width: 90%;
  overflow: hidden;
}

.skills-list {
  width: 150%;
  animation: scrollanimation 19s linear infinite;
}

.skill-item {
  display: inline-block;
  font-size: 1rem;
  padding: 10px;
  color: #000000;
  border-radius: 5px;
}

@keyframes scrollanimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.typing-effect-container {
  margin-right: 5%;
  display: inline-block;
}

.typing-effect {
  border-right: 3px solid;
  font-size: 1.8rem; 
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 3s steps(16, end),
  cursor .6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}

@keyframes typing {
  from { width: 0; }
  to { width: 16ch; }
}






/* Responsive design */
@media (max-width: 768px) {
  .about-section {
    padding: 40px 10px;
    height: auto; /* Adjust height for mobile */
  }

  .about-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .about-left, .about-right {
    flex: none; /* Reset flex properties */
    width: 95%;
  }

  .about-right {
    order: 1; /* Move about-right content to the top */
    text-align: center;
  }

  .about-left {
    order: 2; /* Move carousel below the about-right content */
  }

  .about-right h3 {
    font-size: 1.2rem; /* Adjust font size for mobile */
  }

  .about-right p {
    font-size: 0.9rem; /* Adjust font size for mobile */
  }

  .carousel .slide img {
    width: 90%; /* Adjust image size for mobile */
  }

  .skills-section {
    width: 90%; /* Adjust skills section width for mobile */
  }

  .skills-list {
    width: 170%;
    animation: scrollanimation 20s linear infinite;
  }

  .typing-effect {
    font-size: 1.5rem; /* Adjust typing effect font size for mobile */
  }
}

